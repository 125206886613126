import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from "./../../environments/environment";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const TOKEN_NAME="x-auth-token";
export interface UserCredential {
    _id: string;
    isAdmin: boolean;
    userName: string;
}

export interface UserProfile {
    email: string;
    userName: string;
    coopID?: string;
}

export interface NewUser {
    email: string;
    userName: string;
    password: string;
    confirmPassword: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _myProfile;
    private _userRoute: string = `${environment.apiRoot}/user`;

    constructor(private http: HttpClient) { }

    private _get_requestOptions() {
        const token = this.get_token();
        const httpOptions = {
            headers: new HttpHeaders({}).set(TOKEN_NAME, token)
        }
        return httpOptions;
    }

    get_authedRequestHeader() {
        return this._get_requestOptions();
    }

    register(user: NewUser) {
        const apiPoint = this._userRoute;

        const newUserObj = {
            email: user.email,
            userName: user.userName.trim(),
            password: user.password
        };
        return this.http.post(apiPoint, newUserObj)
                .pipe(
                    map((response) => {
                        const result = response;
                        if (result && result['token']) {
                            store_token(result['token']);
                            return true;
                        }
                        return false;
                    })
                ).toPromise();
    }

    store_myProfile(profile) {
        this._myProfile = profile;
    }

    login(credential) {
        return this.http.post(`${this._userRoute}/login`, credential)
            .pipe(
                map((response) => {
                    let result = response;
                    if (result && result['token']) {

                        store_token(result['token']);
                        return true;
                    }
                    return false;
                })
            );
    }

    isLoggedIn() {
        const token = get_token();
        if (!token) return false;

        return !(new JwtHelperService().isTokenExpired(token));
    }

    logout() {
        localStorage.removeItem(TOKEN_NAME);
    }

    async email_valid() {
        return (await this.myProfile()).emailValid;
    }

    currentUser(): UserCredential {
        const token = get_token();
        if (!token) return null;

        return new JwtHelperService().decodeToken(token);
    }

    get_profile() {
        const userId = this.currentUser()._id;
        const routeAddress = `${this._userRoute}/profile/${userId}`;
        const httpOptions = this._get_requestOptions();

        return this.http.get(routeAddress, httpOptions).toPromise();
    }

    async myProfile() {
        this._myProfile = await this.get_profile();

        return this._myProfile;
    }

    email_exists(email: string): Observable<boolean> {
        const apiPoint = `${this._userRoute}/email`;
        return this.http.post(apiPoint, {email: email}).pipe(
            map( (response) => response['exists'])
        );
    }

    change_userProfile(data) {
        const apiPoint = `${this._userRoute}?type=profile`;
        const httpOptions = this._get_requestOptions();

        return this.http.put(apiPoint, data, httpOptions).toPromise();
    }
    update_coopInfo(data) {
        const apiPoint = `${this._userRoute}/coop`;
        const httpOptions = this._get_requestOptions();
        return this.http.put(apiPoint, data, httpOptions).toPromise();
    }
    get_token() {
        return get_token();
    }

    async validate_email(token, digitsToken?) {
        const apiPoint = `${this._userRoute}/validate/email`;
        let payload;
        if (digitsToken) {
            payload = {
                userID: this.currentUser()._id,
                digitsToken: digitsToken
            };
        } else {
            payload = { token: token };
        }

        try {
            const result = await this.http.post(apiPoint, payload).toPromise();
            store_token(result['token']);
            return true;
        } catch (e) {
            return false;
        }
    }

    async resend_confirmEmail(emailAddress: string) {
        const apiPoint = `${this._userRoute}/validate/resend`;
        const payload = { email: emailAddress };
        const httpOptions = this._get_requestOptions();
        return this.http.post(apiPoint, payload, httpOptions).toPromise();
    }
}

function store_token(token: string) {
    localStorage.setItem(TOKEN_NAME, token);
}

function get_token() {
    return localStorage.getItem(TOKEN_NAME) || "";
}

