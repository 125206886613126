import { ApperyioHelperService } from '../scripts/apperyio/apperyio_helper';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CodeInputComponent } from 'angular-code-input';
import { ModalController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { AlertsService } from '../services/alerts.service';

@Component({
    selector: 'app-popup-validemail',
    templateUrl: './popup-validemail.page.html',
    styleUrls: ['./popup-validemail.page.scss'],
})
export class PopupValidemailPage implements OnInit {
    @ViewChild('codeInput', {static: false}) codeInput !: CodeInputComponent;

    public loading: boolean = false;
    public canSubmit: boolean = false;
    public codeLength: number = 4;
    public codeHidden: boolean = false;
    public digitsToken: string = '';

    @Input() userName: string;
    @Input() email: string;
    @Input() contact: any;

    constructor(
        public Apperyio: ApperyioHelperService,
        private modalController: ModalController,
        private authService: AuthService,
        private alertService: AlertsService
    ) {
    }

    ngOnInit() {
    }

    async onSubmitValidation() {
        this.loading = true;

        const result = await this.authService.validate_email('', this.digitsToken);

        if (result) {
            this.modalController.dismiss();
            this.authService.store_myProfile({
                userName: this.userName,
                email: this.email,
                contact: this.contact
            });
            this.Apperyio.navigateTo('farmregistration');
        } else {
            const userWantResend = await this.alertService.prompt_emailValidationFail();
            if (userWantResend) {
                this.codeInput.reset();
                this.loading = false;
                const address = (await this.authService.myProfile()).email;
                await this.authService.resend_confirmEmail(address);
            }
        }

            
    }

    onDismiss() {
        this.modalController.dismiss();
    }

    onCodeChanged($event) {
        const code: string = $event;
        this.digitsToken = code;

        if (code.length < this.codeLength) {
            this.canSubmit = false;
        } else {
            this.canSubmit = true;
        }
    }

}
