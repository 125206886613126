import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {

    private _loading;

    constructor(
        private _alertController: AlertController,
        private _loadingController: LoadingController
    ) { }

    async prompt_yesNo(title: string, message: string) {

        return new Promise(async (resolve, reject) => {
            const alert = await this._alertController.create({
                cssClass: 'my-custom-class',
                header: title,
                message: message,
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            resolve(false);
                        }
                    },
                    {
                        text: 'Confirm',
                        handler: () => {
                            resolve(true);
                        }
                    }
                ]
            });

            await alert.present();
        });
    }

    async prompt_emailValidationFail() {
        return new Promise(async (resolve, reject) => {
            const alert = await this._alertController.create({
                cssClass: 'my-custom-class',
                header: 'Email Validation Failed',
                message: 'Failed, please try again, or maybe you want us send another email?',
                buttons: [
                    {
                        text: 'Try Again',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            resolve(false);
                        }
                    },
                    {
                        text: 'Re-send Email',
                        handler: () => {
                            resolve(true);
                        }
                    }
                ]
            });

            await alert.present();
        });
    }

    async alert_info(message: string) {
        const alert = await this._alertController.create({
            cssClass: 'my-custom-class',
            header: '',
            subHeader: '',
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

    async onLoading(message: string) {
        this._loading = await this._loadingController.create({
            cssClass: 'my-custom-class',
            message: message
        });
        await this._loading.present();
    }

    async onLoadingFinished() {
        if (this._loading)
            await this._loading.dismiss();
    }

}
