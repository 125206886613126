import {
    NgModule
} from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';
const routes: Routes = [{
        path: '',
        redirectTo: 'screen1',
        pathMatch: 'full'
    },
    {
        path: 'screen1',
        loadChildren: './LoginPage/LoginPage.module#LoginPagePageModule',
    },
    {
        path: 'welcomepage',
        loadChildren: './LiveMilkPrices/LiveMilkPrices.module#LiveMilkPricesPageModule',
    },
    {
        path: 'registration',
        loadChildren: './Registration/Registration.module#RegistrationPageModule',
    },
    {
        path: 'farmregistration',
        loadChildren: './FarmRegstration/FarmRegstration.module#FarmRegstrationPageModule',
    },
    {
        path: 'processorregistration',
        loadChildren: './ProcessorRegistration/ProcessorRegistration.module#ProcessorRegistrationPageModule',
    },
    {
        path: 'milkporductionphoto',
        loadChildren: './MilkPorductionPhoto/MilkPorductionPhoto.module#MilkPorductionPhotoPageModule',
    },
    {
        path: 'uploadphoto',
        loadChildren: './UPLOADPHOTO/UPLOADPHOTO.module#UPLOADPHOTOPageModule',
    },
    {
        path: 'photowithocr',
        loadChildren: './PHOTOWITHOCR/PHOTOWITHOCR.module#PHOTOWITHOCRPageModule',
    },
    {
        path: 'livemilkprices',
        loadChildren: './PriceLocking/PriceLocking.module#PriceLockingPageModule',
    },
    {
        path: 'orderverification',
        loadChildren: './OrderVerification/OrderVerification.module#OrderVerificationPageModule',
    },
    {
        path: 'lockingsummary',
        loadChildren: './LockingSummary/LockingSummary.module#LockingSummaryPageModule',
    },
    {
        path: 'contractconfirmed',
        loadChildren: './ContractConfirmed/ContractConfirmed.module#ContractConfirmedPageModule',
    }
];
@NgModule({
    imports: [RouterModule.forRoot(
        routes, {
            enableTracing: false,
            useHash: true
        }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {}
