import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from "./../../environments/environment";
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class FarmService {

    private _farmRoute: string = `${environment.apiRoot}/farm`;
    constructor(
        private _authService: AuthService,
        private http: HttpClient
    ) { }

    update_farmInfo(farmInfo) {
        const httpHeader = this._authService.get_authedRequestHeader();
        const api = `${this._farmRoute}/info`;
        return this.http.put(api, farmInfo, httpHeader).toPromise();
    }

    update_production(production) {
        const httpHeader = this._authService.get_authedRequestHeader();
        const api = `${this._farmRoute}/production`;
        return this.http.post(api, production, httpHeader).toPromise();
    }

    edit_production(production) {
        const httpHeader = this._authService.get_authedRequestHeader();
        const api = `${this._farmRoute}/production`;
        return this.http.put(api, production, httpHeader).toPromise();
    }

    update_herdInfo(herdInfo) {
        const httpHeader = this._authService.get_authedRequestHeader();
        const api = `${this._farmRoute}/herd`;
        return this.http.post(api, herdInfo, httpHeader).toPromise();
    }

    add_farm(farm) {
        const httpHeader = this._authService.get_authedRequestHeader();
        const api = `${this._farmRoute}/add`;
        return this.http.post(api, farm, httpHeader).toPromise();
    }
}
