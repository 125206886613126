import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from "./../../environments/environment";
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class LockPriceService {
    private _locked;
    private _lockingResult;
    private _lockRoute: string = `${environment.apiRoot}/user/lockprice`;

    constructor(
        private _authService: AuthService,
        private http: HttpClient
    ) { }

    lock_price(locked) {
        this._locked = locked;
    }

    locked_price() {
        return this._locked;
    }

    submit_locking(lockingObj) {
        const httpHeader = this._authService.get_authedRequestHeader();
        const api = `${this._lockRoute}`;
        return this.http.post(api, lockingObj, httpHeader).toPromise();
    }

    locking_result() {
        return this._lockingResult;
    }

    set_lockingResult(result) {
        this._lockingResult = result;
    }

    get_priceLockings() {
        const httpHeader = this._authService.get_authedRequestHeader();
        const api = `${this._lockRoute}`;
        return this.http.get(api, httpHeader).toPromise();
    }
}
