import { Injectable } from '@angular/core';
import { environment } from "./../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

export const units = [
    'GBP/Liter',
    'EUR/Kg',
    'NZD/Kg',
    'USD/Kg',
    'USD/CINT'
]

@Injectable({
    providedIn: 'root'
})
export class PricesService {
    private _priceRoute: string = `${environment.apiRoot}/price`;

    constructor(
        private _authService: AuthService,
        private http: HttpClient
    ) { }

    get_forwards() {
        const httpHeader = this._authService.get_authedRequestHeader();
        const api = `${this._priceRoute}/forwards`;
        return this.http.get(api, httpHeader).toPromise();
    }

    priceToday() {
        return [
            {
                month: 'Mar',
                price: [
                    10,
                    12,
                    7,
                    6,
                    17,
                ],
            },
            {
                month: 'Apr',
                price: [
                    12,
                    15,
                    8,
                    7,
                    14,
                ],
            },
            {
                month: 'May',
                price: [
                    10,
                    11,
                    6,
                    8,
                    14,
                ],
            },
            {
                month: 'Jun',
                price: [
                    11,
                    13,
                    7,
                    7,
                    18,
                ],
            },
        ]
    }
}
