export const constants = {
    /**
     * Settings
     */
    Settings: {}
};
export const routes = {
    "Screen1": "screen1",
    "WelcomePage": "welcomepage",
    "Registration": "registration",
    "FarmRegstration": "farmregstration",
    "ProcessorRegistration": "processorregistration",
    "MilkPorductionPhoto": "milkporductionphoto",
    "UPLOADPHOTO": "uploadphoto",
    "PHOTOWITHOCR": "photowithocr",
    "LiveMilkPrices": "livemilkprices",
    "OrderVerification": "orderverification",
    "LockingSummary": "lockingsummary",
    "ContractConfirmed": "contractconfirmed",
};
export const pushSettings = {
    appID: '144f1496-eb28-4f34-9d6a-703c107f2c04',
    baseUrl: 'https://api.appery.io/rest/push/reg',
    initOptions: {}
};